import { DownOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Layout, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from 'src/store/user';
import { toast } from 'react-hot-toast';

const flagIcon = [
  {
    flag: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/DE.svg',
    code: 'de'
  },
  {
    flag: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg',
    code: 'en'
  },
  {
    flag: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/MA.svg',
    code: 'ar'
  },
  {
    flag: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg',
    code: 'fr'
  },
  {
    flag: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/ES.svg',
    code: 'es'
  }
];

const { Header: DefaultHeader } = Layout;

const media = {
  logo: '/assets/images/logo-mini.png'
};

const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const ImageWrapper = styled.img`
  width: 30px;
`;

const LangSwitcher = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;
  height: auto;

  img {
    width: 36px;
    max-width: none;
  }
`;

const Header = () => {
  const navigate = useNavigate();
  const user = useUserStore(state => state.user);
  const logoutUser = useUserStore(state => state.logoutUser);
  const [lang, setLang] = useState({
    flag: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg',
    code: 'en'
  });

  // Load selected language from localStorage on component mount
  useEffect(() => {
    const savedLang = localStorage.getItem('selectedLang');
    if (savedLang) {
      const parsedLang = JSON.parse(savedLang);
      setLang(parsedLang);
    }
  }, []);

  // Google translate initialization
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        autoDisplay: false
      },
      'google_translate_element'
    );
  };

  useEffect(() => {
    var addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  // Handle language change and save to localStorage
  const handleLangChange = e => {
    const { key } = e;
    const selectedLang = flagIcon.find(({ code }) => code === key);
    setLang(selectedLang);

    // Save the selected language to localStorage
    localStorage.setItem('selectedLang', JSON.stringify(selectedLang));

    const ele = document.querySelector('.goog-te-combo');
    ele.value = key;
    ele.dispatchEvent(new Event('change'));
  };

  return (
    <DefaultHeader
      style={{
        padding: '0.5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'sticky',
        left: 0,
        top: 0,
        width: '100%',
        zIndex: 99
      }}
    >
      <Container>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <img
            src={media.logo}
            alt=""
            style={{ cursor: 'pointer', width: "80px", height: "auto" }}
            onClick={() => {
              navigate('/property/new');
            }}
          />
          <Space size="large">
            <Typography.Title
              level={5}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/dashboard/groups/groups-home')}
            >
              Dashboard
            </Typography.Title>
            <Button
              ghost
              type="primary"
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '1rem',
                padding: '24px',
                color: '#000'
              }}
              size="large"
              onClick={() => {
                navigate('/property/new');
              }}
            >
              Add a new property
            </Button>
            <Dropdown
              menu={{
                items: flagIcon.map(({ flag, code }) => ({
                  key: code,
                  label: (
                    <Typography.Paragraph
                      style={{ marginBottom: 0, padding: '0.2rem' }}
                    >
                      <ImageWrapper src={flag} alt="deFlag" />
                    </Typography.Paragraph>
                  )
                })),
                onClick: handleLangChange
              }}
              trigger={['click']}
            >
              <LangSwitcher>
                <img src={lang.flag} alt="" />
              </LangSwitcher>
            </Dropdown>
            <div
              id="google_translate_element"
              style={{ display: 'none' }}
            ></div>
            <Dropdown
              overlayStyle={{
                width: 'fit-content',
                minWidth: '200px',
                padding: '10px'
              }}
              trigger={['click']}
              menu={{
                items: [
                  {
                    key: 'dashboard',
                    label: (
                      <Link to="/account/personal-details">Edit Profile</Link>
                    )
                  },
                  {
                    key: 'logout',
                    label: (
                      <Button
                        danger
                        block
                        onClick={() => {
                          localStorage.removeItem('AvJO)%zOxm}S/iy');
                          logoutUser();
                          toast.success('Logout Successful!');
                        }}
                      >
                        Logout
                      </Button>
                    )
                  }
                ]
              }}
            >
              <Button
                size="large"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px'
                }}
                type="primary"
              >
                <Avatar
                  style={{
                    backgroundColor: '#8E03CF',
                    textTransform: 'uppercase'
                  }}
                  size="small"
                >
                  {user?.fName?.charAt(0)}
                </Avatar>
                <DownOutlined />
              </Button>
            </Dropdown>
          </Space>
        </div>
      </Container>
    </DefaultHeader>
  );
};

export default Header;
