import d from 'dayjs';
import DayCard from './day-card';
import Spinner from './spinner';
import { useState } from 'react';

function BookingCalendar({
  month,
  year,
  setSelectedDate,
  selectedDate,
  updatingCalendar,
  roomId,
  room,
}) {
  const from = d().set('year', year).set('month', month).set('date', 1);
  const startOfMonth = from.startOf('month');
  const days = [...Array(parseInt(from.daysInMonth()))];

  const [isDragging, setIsDragging] = useState(false);

  const toggleDateSelection = day => {
    setSelectedDate(prev => {
      const newSelection = new Set(prev);

      if (newSelection.has(day)) {
        newSelection.delete(day);
        if (newSelection.size === 0) newSelection.add(day);
        return Array.from(newSelection);
      } else {
        newSelection.add(day);

        const selectedArray = Array.from(newSelection);
        const minDay = Math.min(...selectedArray);
        const maxDay = Math.max(...selectedArray);

        for (let i = minDay; i <= maxDay; i++) {
          newSelection.add(i);
        }

        return Array.from(newSelection);
      }
    });
  };

  const handleMouseDown = day => {
    if (day) {
      setIsDragging(true);
      toggleDateSelection(day);
    }
  };

  const handleMouseEnter = day => {
    if (isDragging && day) {
      toggleDateSelection(day);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  return (
    <div
      className="__calender"
      style={{ position: 'relative', height: 'fit-content' }}
    >
      {updatingCalendar && (
        <div
          style={{
            position: 'absolute',
            background: '#1d1d1d5b',
            height: '100%',
            width: '100%',
            borderRadius: '10px'
          }}
        >
          <Spinner />
        </div>
      )}
      <div className="__calender-week-names">
        <h3>SUNDAY</h3>
        <h3>MONDAY</h3>
        <h3>TUESDAY</h3>
        <h3>WEDNESDAY</h3>
        <h3>THURSDAY</h3>
        <h3>FRIDAY</h3>
        <h3>SATURDAY</h3>
      </div>
      <div className="__calender-days" onMouseUp={handleMouseUp}>
        {startOfMonth &&
          [...Array(startOfMonth.day())].map((_, i) => (
            <div className="__calender_day --empty" key={'empty-' + i + 1}>
              <span></span>
            </div>
          ))}
        {days.map((_, i) => {
          return (
            <DayCard
              key={i + 1}
              {...{
                handleMouseDown,
                handleMouseEnter,
                selectedDate,
                setSelectedDate,
                from,
                date: i + 1,
                month,
                year,
                roomId,
                room,
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default BookingCalendar;
