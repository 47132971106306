import { Suspense, lazy, useEffect, useState } from 'react';
import styles from './account-details.page.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Balancer from 'react-wrap-balancer';
import ImageInput from '../../components/image-input/image-input';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateProfilePic } from '../../api/user.req';
import Header from 'src/components/Header';
import { useUserStore } from 'src/store/user';

const PersonalDetails = lazy(() =>
  import('../../components/personal-details/personal-details')
);
const ChangePassword = lazy(() =>
  import('../../components/change-password/change-password')
);

const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp'
];

const tabs = [
  {
    name: 'Personal Details',
    description: 'Please enter your personal details',
    icon: '/assets/images/account-icons/user.svg',
    activeIcon: '/assets/images/account-icons/user-active.svg',
    path: '/account/personal-details'
  },
  {
    name: 'Change Password',
    description: 'Update your account security',
    icon: '/assets/images/account-icons/lock.svg',
    activeIcon: '/assets/images/account-icons/lock-active.svg',
    path: '/account/change-password'
  }
];

const Pages = {
  [tabs[0].path]: PersonalDetails,
  [tabs[1].path]: ChangePassword
};

function AccountDetailsPage() {
  const currentUser = useUserStore(state => state.user);
  
  const validPaths = tabs.map(tab => tab.path);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectedTab, setSelectedTab] = useState(pathname);
  const [image, setImage] = useState(null);
  const client = useQueryClient();

  const profilePicMutation = useMutation({
    mutationFn: async e => {
      const image = e?.target?.files?.[0];
      if (!image) throw new Error('custom error');
      setImage(image);
      if (!ACCEPTED_IMAGE_TYPES.includes(image.type)) {
        console.error('Invalid file type');
        throw new Error('custom error');
      }
      if (image.size > 10_000_000) {
        console.error('File size exceeded 10 MB');
        throw new Error('custom error');
      }
      const formData = new FormData();
      formData.append('profilePic', image);
      const response = await updateProfilePic(formData);
      return response?.data;
    },
    onSuccess: data => {
      client.invalidateQueries('user');
      if (data?.status === 'success') {
        console.log('Profile Picture Updated Successfully');
      }
    },
    onError: error => {
      console.error(error?.message || 'Something went wrong');
    }
  });

  useEffect(() => {
    setSelectedTab(() =>
      validPaths.includes(pathname) ? pathname : validPaths[0]
    );
  }, [pathname]);

  const Page = Pages[selectedTab];

  return (
    <div className={styles.accountDetailsPage}>
      <Header />
      <div className={styles.container}>
        <div className={styles.navContainer}>
          <nav>
            {tabs.map(tab => (
              <li
                onClick={() => {
                  navigate(tab.path);
                }}
                key={tab.name}
                className={`${styles.tab} ${
                  selectedTab === tab.path ? styles.selected : ''
                }`}
              >
                <img
                  src={selectedTab === tab.path ? tab.activeIcon : tab.icon}
                  alt={tab.name}
                />
                <p>{tab.name}</p>
              </li>
            ))}
          </nav>
        </div>
        <Suspense fallback={<Loader />}>
          <main className={styles.page}>
            <div className={styles.head}>
              <div className={styles.info}>
                <h1>{tabs.find(tab => tab.path === selectedTab).name}</h1>
                <p>
                  <Balancer>
                    {tabs.find(tab => tab.path === selectedTab).description}
                  </Balancer>
                </p>
              </div>
              <ImageInput
                profile
                src={currentUser?.profilePic}
                image={image}
                onChange={profilePicMutation.mutate}
              />
            </div>
            {Page && currentUser && <Page currentUser={currentUser} />}
          </main>
        </Suspense>
      </div>
    </div>
  );
}

function Loader() {
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.loader}></div>
    </div>
  );
}

export default AccountDetailsPage;
